import React, {Suspense, lazy} from "react";
import CircularProgress from "../components/CircularProgress";
import {Route, Routes} from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";
import {useSelector} from "react-redux";

// Load components
const CoreWebHome = lazy(() => import('./CoreWebHome'));
// const CoreWebHome = lazy(() => import('./ComingSoon'));
const SignInPage = lazy(() => import('./SignIn'));
const SignUpPage = lazy(() => import('./SignUp'));
const ForgotPasswordPage = lazy(() => import('./ForgotPassword'));
const ResetPasswordPage = lazy(() => import('./ResetPassword'));
const VerifyEmailPage = lazy(() => import('./VerifyEmail'));
const Verify2FAPage = lazy(() => import('./Verify2FA'));
const Reset2FAPage = lazy(() => import('./Reset2FA'));
const HomePage = lazy(() => import('./Home'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const TermsOfUse = lazy(() => import('./TermsOfUse'));
const CookiePreferences = lazy(() => import('./CookiePreferences'));
const LegalNotices = lazy(() => import('./LegalNotices'));

// Sample conditions

const App = () => {
    const {isAuthenticated, emailVerified, requires2FA} = useSelector(({auth}) => auth);

    return (
        <Suspense fallback={<CircularProgress className="flex items-center justify-center h-screen"/>}>
            <Routes>
                {/* Public Routes */}
                <Route path="/" element={<CoreWebHome/>}/>
                <Route path="/login" element={<SignInPage/>}/>
                <Route path="/register" element={<SignUpPage/>}/>
                <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>
                <Route path="/reset-password" element={<ResetPasswordPage/>}/>
                <Route path="/contact-us" element={<CoreWebHome/>}/>

                {/* Legal */}
                <Route path="/legal/privacy" element={<PrivacyPolicy/>}/>
                <Route path="/legal/terms-of-use" element={<TermsOfUse/>}/>
                <Route path="/legal/cookie-preferences" element={<CookiePreferences/>}/>
                <Route path="/legal/legal-notices" element={<LegalNotices/>}/>

                {/* Conditional Routes */}
                <Route
                    path="/verify-email"
                    element={
                        <ProtectedRoute
                            element={VerifyEmailPage}
                            authCondition={isAuthenticated && !emailVerified} // User must be logged in but email not verified
                            redirectPath="/home" // Redirect to home if email already verified
                        />
                    }
                />
                <Route
                    path="/verify-2fa"
                    element={
                        <ProtectedRoute
                            element={Verify2FAPage}
                            authCondition={isAuthenticated && requires2FA} // User must be logged in and require 2FA
                            redirectPath="/login" // Redirect to login if not authenticated or no 2FA required
                        />
                    }
                />
                <Route
                    path="/reset-2fa"
                    element={
                        <ProtectedRoute
                            element={Reset2FAPage}
                            authCondition={isAuthenticated} // User must be logged in to reset 2FA
                            redirectPath="/login"
                        />
                    }
                />

                <Route
                    path="/home"
                    element={
                        <ProtectedRoute
                            element={HomePage}
                            authCondition={isAuthenticated} // Only logged-in users can access home
                            redirectPath="/login"
                        />
                    }
                />
            </Routes>
        </Suspense>);
}
export default App;