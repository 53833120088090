import {
    SET_AUTH_USER,
    SIGNOUT_USER_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
    token: JSON.parse(localStorage.getItem('token')) || null,
    userId: JSON.parse(localStorage.getItem('userId')) || null,
    email: JSON.parse(localStorage.getItem('email')) || null,
    isAuthenticated: !!JSON.parse(localStorage.getItem('token')),
    emailVerified: JSON.parse(localStorage.getItem('isVerified')) || false,
    requires2FA: JSON.parse(localStorage.getItem('twoFactorEnabled')) || false
};

const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {


        case SET_AUTH_USER: {
            const {token, userId, email, isAuthenticated, emailVerified, requires2FA} = action.payload;


            return {
                ...state,
                token,
                userId,
                email,
                isAuthenticated,
                emailVerified,
                requires2FA
            };
        }

        case SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                token: null,
                userId: null,
                email: null,
                isAuthenticated: false,
                emailVerified: false,
                requires2FA: false
            }
        }
        default:
            return state;
    }
}
export default Auth;
