import React from 'react';
import {Navigate} from 'react-router-dom';

// Example of a ProtectedRoute Component
const ProtectedRoute = ({element: Element, authCondition, redirectPath, ...rest}) => {
    if (!authCondition) {
        // Redirect if the condition is not met
        return <Navigate to={redirectPath}/>;
    }
    // If the user is authenticated, render the desired element (protected page)
    return <Element {...rest} />;
};

export default ProtectedRoute;