import {configureStore} from '@reduxjs/toolkit';

import rootReducer from '../reducers'



export default function configureAppStore(preloadedState) {
    return configureStore({
        reducer: rootReducer, // Combine all reducers here
        preloadedState, // Optional: Initial state can be passed here
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware(), // Customize middleware if needed
        devTools: process.env.NODE_ENV !== "production", // Enable DevTools only in development
    });
}
