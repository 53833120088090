import React from "react";
import ReactDOM from "react-dom/client";

import CoreApp from './CoreApp';
import * as serviceWorker from './registerServiceWorker';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CoreApp/>
);

serviceWorker.unregister();
